import * as React from 'react'
import { RadioChangeEvent } from 'antd'
import { AxiosError } from 'axios'
import palette from 'lib/styles/palette'

import moment from 'moment'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { TimePicker, Radio } from 'antd'
import 'antd/dist/antd.min.css'
import locale from 'antd/es/date-picker/locale/ko_KR'
import 'antd/es/date-picker/style/index'

import { ChevronDownIcon } from 'components/Icons/svg'
import { DateChecks } from 'components/DateChecks'
import Postcode from 'components/Postcode'

import { Modal } from 'components/Modal'
import { useModal } from 'components/Modal/hooks/useModal'

import { authAtom } from 'stores/atoms'
import { ownerAtom } from 'stores/atoms/auth'

import { registerShop } from 'services/shop.service'

import { queryPramsAtom } from 'stores/atoms/users'

import { Steps, Step } from 'components/Steps'
import styled from 'styled-components'
import { useSteps } from 'components/Steps/hooks/useSteps'
import { RegisterTemplate } from 'template/RegisterTemplate'
import { Category } from 'components/CategoriesSelects/Category'
import { useCategorySelect } from 'components/CategoriesSelects/hooks/useCategorySelect'
import { TextField } from 'components/common'
import { useHandleShopName } from 'hooks/useHandleShopName'
import { useHandleRecommendId } from 'hooks/useHandleRecommendId'

export type AddressInfoType = {
  addressBasic: string
  zipCode: number
  dong: string
}

export const RegisterOwnerPage: React.FC = () => {
  const authData = useRecoilValue(authAtom)
  const queryParamsAtom = useRecoilValue(queryPramsAtom)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const setOwnerAuthData = useSetRecoilState(ownerAtom)
  const { isShown, toggle } = useModal()
  const [timeInterval, setTimeInterval] = React.useState('00:10')
  const [categories, setCategories] = React.useState<string[]>([])
  const categorySelects = useCategorySelect()
  const [isLoading, setIsLoading] = React.useState(false)
  const [regularBusinessDayOfWeek, setRegularBusinessDayOfWeek] = React.useState({})

  const [startBusinessTime, setStartBusinessTime] = React.useState<string | undefined>(
    moment('10:00', 'HH:mm').format('HH:mm'),
  )

  const [endBusinessTime, setEndBusinessTime] = React.useState<string | undefined>(
    moment('20:00', 'HH:mm').format('HH:mm'),
  )

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  })

  const { nextStep, prevStep, activeStep } = useSteps({ initialStep: 0 })

  const [address, setAddress] = React.useState<AddressInfoType>({
    addressBasic: '',
    zipCode: 0,
    dong: '',
  })

  React.useEffect(() => {
    setValue('addressBasic', address.addressBasic)
    setValue('zipCode', address.zipCode)
    setValue('categories', categories)
  }, [address.addressBasic, address.zipCode, categories, setValue])

  const isBusinessDayChecked = () => {
    return Object.values(regularBusinessDayOfWeek).filter(Boolean).length > 0
  }

  const onChangeTimeInterval = (e: RadioChangeEvent) => {
    setTimeInterval(e.target.value)
  }

  const onSubmit = () => {
    if (!categories.length) {
      return
    }
    return nextStep()
  }

  const onSubmitNextStep = async (data: any) => {
    if (!authData.empNo) {
      return
    }
    const payload = {
      ...data,
      name: shopName.value,
      recommendId: recommendId.status === 'SUCCESS01' ? recommendId.value : '',
      regularBusinessDayOfWeek,
      ownerEmpNo: authData.empNo,
      bookingTimeUnit: timeInterval,
      startBusinessTime,
      endBusinessTime,
    }

    setIsLoading(true)

    try {
      const {
        data: {
          data: { freeTrialEndDate, shopNo, token },
          errorResponse,
        },
      } = await registerShop(payload)
      if (errorResponse.status !== 200) {
        alert(errorResponse.message)
        return
      }
      setOwnerAuthData({ freeTrialEndDate, shopNo, token })
      onSumitFormAction(token)
    } catch (error: unknown) {
      const err = error as AxiosError
      alert(err.response?.data.message)
      prevStep()
    } finally {
      setIsLoading(false)
    }
  }

  const onSumitFormAction = (token: string) => {
    let newInput = document.createElement('input')
    newInput.setAttribute('type', 'hidden')
    newInput.setAttribute('name', 'token')
    newInput.setAttribute('value', token)

    if (formRef.current) {
      formRef.current.method = 'post'
      formRef.current.action = queryParamsAtom.returnUrl
      formRef.current.appendChild(newInput)
      formRef.current.submit()
      return
    }
  }

  const content = <Postcode toggle={toggle} setAddress={setAddress} />
  const { shopName, validateShopName } = useHandleShopName()
  const { recommendId, onChangeRecommendId } = useHandleRecommendId()
  return (
    <RegisterTemplate>
      <Steps activeStep={activeStep}>
        <Step>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Heading
                fontSize={{
                  base: '16px',
                  md: '24px',
                }}
                textAlign="center"
                mb={42}
              >
                우리샵 등록하기
              </Heading>

              <FormControl mb={42} isRequired>
                <FormLabel htmlFor="name">샵 정보 입력</FormLabel>
                <TextField
                  onInvalid={(e) => e.preventDefault()}
                  placeholder="샵 이름"
                  onChange={validateShopName}
                  value={shopName.value}
                  helpText={shopName.helpText}
                  status={shopName.status}
                />
              </FormControl>
              <FormControl id="categories" mb={30} isRequired>
                <FormLabel>업종 선택</FormLabel>
                <Category categorySelects={categorySelects} setCategories={setCategories} />
              </FormControl>
              <FormControl isRequired id="address" mt={2.5}>
                <FormLabel>주소 입력</FormLabel>
                <Flex alignItems="flex-end" onClick={toggle}>
                  <Input
                    type="text"
                    autoComplete="off"
                    fontSize="sm"
                    style={{ boxShadow: 'none', cursor: 'pointer' }}
                    variant="flushed"
                    borderColor="line_gray"
                    focusBorderColor={errors.addressBasic ? 'error_color' : 'main_blue'}
                    readOnly
                    placeholder="샵 주소"
                    mr={4}
                    {...register('addressBasic', {
                      required: true,
                    })}
                  />
                  <Button borderRadius={100} minW={75} variant="outline" onClick={toggle}>
                    주소검색
                  </Button>
                </Flex>
              </FormControl>

              <FormControl id="addressDetail" mt={2.5} mb={42}>
                <Input
                  type="text"
                  autoComplete="off"
                  fontSize="sm"
                  style={{ boxShadow: 'none' }}
                  variant="flushed"
                  borderColor="line_gray"
                  focusBorderColor={errors.addressDetail ? 'error_color' : 'main_blue'}
                  placeholder="상세 주소 (필수)"
                  {...register('addressDetail', {
                    required: true,
                  })}
                />
              </FormControl>

              <FormControl id="addressDetail" mb={42}>
                <FormLabel>추천인 입력</FormLabel>
                <TextField
                  placeholder="추천인 아이디"
                  helpText={recommendId.helpText}
                  status={recommendId.status}
                  value={recommendId.value}
                  onChange={onChangeRecommendId}
                />
              </FormControl>
            </Box>
            <Box>
              <Button
                bgColor="main_black"
                _hover={{
                  backgroundColor: palette.black_hover,
                }}
                _active={{
                  backgroundColor: palette.main_black,
                }}
                isFullWidth
                size="lg"
                type="submit"
                disabled={!(shopName.status === 'SUCCESS01' && isValid)}
              >
                다음
              </Button>
            </Box>
          </StyledForm>

          <Modal
            headerText="우리샵 등록하기"
            isShown={isShown}
            hide={toggle}
            modalContent={content}
          />
        </Step>

        <Step>
          <StyledForm onSubmit={handleSubmit(onSubmitNextStep)} ref={formRef}>
            <Box>
              <Heading
                fontSize={{
                  base: '16px',
                  md: '24px',
                }}
                textAlign="center"
                mb={42}
                mt={{ md: 10 }}
              >
                우리샵 등록하기
              </Heading>

              <FormControl id="regularBusinessDayOfWeek" mb={42}>
                <FormLabel>영업일 체크</FormLabel>
                <DateChecks setRegularBusinessDayOfWeek={setRegularBusinessDayOfWeek} />
                <FormHelperText fontSize="xs" mt={4} color="default_text_gray">
                  공비서예약 연동 시 이용하는 고객에게 영업일로 표기됩니다.
                </FormHelperText>
              </FormControl>
              <FormControl id="openTime" mb={42}>
                <FormLabel>영업 시간</FormLabel>
                <TimePickerBlock>
                  <GongbizTimePicker
                    locale={{ ...locale, lang: { ...locale.lang } }}
                    popupStyle={{ height: '168px' }}
                    inputReadOnly
                    defaultValue={moment('A 10:00', 'A h:mm')}
                    allowClear={false}
                    minuteStep={10}
                    format="A h:mm"
                    use12Hours={true}
                    suffixIcon={<ChevronDownIcon />}
                    placeholder="00:00시 오픈"
                    showNow={false}
                    hideDisabledOptions
                    onChange={(time: any) => {
                      setStartBusinessTime(time?.format('HH:mm'))
                    }}
                  />
                  <span>~</span>
                  <GongbizTimePicker
                    locale={{ ...locale, lang: { ...locale.lang } }}
                    popupStyle={{ height: '168px' }}
                    inputReadOnly
                    allowClear={false}
                    minuteStep={10}
                    defaultValue={moment('P 8:00', 'A h:mm')}
                    format="A h:mm"
                    use12Hours={true}
                    suffixIcon={<ChevronDownIcon />}
                    placeholder="00:00시 마감"
                    showNow={false}
                    hideDisabledOptions
                    onChange={(time: any) => {
                      setEndBusinessTime(time?.format('HH:mm'))
                    }}
                  />
                </TimePickerBlock>
              </FormControl>
              <FormControl>
                <FormLabel>예약시간 간격</FormLabel>
                <GongbizRadioBlock
                  onChange={onChangeTimeInterval}
                  value={timeInterval}
                  size="large"
                >
                  <GongbizRadio value="00:10">10분</GongbizRadio>
                  <GongbizRadio value="00:30">30분</GongbizRadio>
                </GongbizRadioBlock>
              </FormControl>
            </Box>

            <Button
              isLoading={isLoading}
              disabled={!isBusinessDayChecked()}
              isFullWidth
              size="lg"
              type="submit"
            >
              완료
            </Button>
          </StyledForm>
        </Step>
      </Steps>
    </RegisterTemplate>
  )
}

const StyledForm = styled.form`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input::placeholder {
    color: ${({ theme }) => theme.color.gray[300]};
  }
`

const TimePickerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  span {
    padding: 0 8px;
    color: #adb4bd;
    font-size: 12px;
  }
`

const GongbizTimePicker = styled(TimePicker)`
  height: 2.75rem;
  width: 100%;
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
    border: 1px solid ${palette.main_blue};
    box-shadow: 0 3px 10px 0 rgba(34, 126, 255, 0.2);
  }
  .ant-picker-input {
    input {
      cursor: pointer;
      color: ${palette.main_blue};
    }
  }

  .ant-picker-suffix {
    padding-right: 4px;
  }
`

const GongbizRadioBlock = styled(Radio.Group)`
  width: 100%;
  margin-top: 0.625rem;
  display: flex;
  align-items: center;
`

const GongbizRadio = styled(Radio)`
  width: 50%;
  display: flex;
  align-items: flex-end;

  height: 100%;
  .ant-radio-inner {
    width: 28px;
    height: 28px;
    border-color: ${palette.default_text_gray};
    transition: all 500ms;
    outline: none;

    &::after {
      width: 32px;
      height: 32px;
      top: 5px;
      left: 5px;
      background-color: ${palette.main_blue};
    }

    &:active {
      box-shadow: none;
    }

    &:hover {
      border-color: ${palette.main_blue};
    }
  }
`
